if (window.matchMedia('(max-width: 767px)').matches) {
  var teasers = document.querySelectorAll('a.ex-highlights-overview__teaser');

  [].forEach.call(teasers, function(teaser) {
    const mobilehref = teaser.getAttribute('href').replace('ex-highlight', 'ex-mobile-highlight');
    teaser.href = mobilehref;
    teaser.addEventListener('click', function() {
      openMobileTeaser(mobilehref);
    });
  });

  function openMobileTeaser(mobilehref) {
    console.log(mobilehref);
    document.querySelector(mobilehref + ' input').click();
  }
}

var ctas = document.querySelectorAll('.ex-highlights-detail__teaser__cta');
var anchors = document.querySelectorAll('.ex-highlights-overview__teaser');

[].forEach.call(ctas, function(cta) {
  cta.addEventListener('click', onCtaClick, false);
});

[].forEach.call(anchors, function(anchor) {
  anchor.addEventListener('click', onAnchorClick, false);
});

function onCtaClick(event) {
  let url = event.target.href;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'ga4Event',
    event_name: 'link_inbound',
    link_inbound: {
      link_domain: 'phoenixcontact.com',
      link_url: url,
      type: 'Product Detail Page',
      module: 'Highlights Overview Teaser',
      event_category: 'Links',
      event_action: 'Product Detail Page',
      event_label: url,
      message_key: undefined,
      message_type: undefined
    }
  });
}

function onAnchorClick(event) {
  var title = event.target.title;
  console.log('track anchor: ', title);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'ga4Event',
    event_name: 'navigation',
    navigation: {
      type: 'Anchor',
      module: 'Highlights Overview Teaser',
      item: title,
      event_category: 'Navigation',
      event_action: 'Anchor',
      event_label: title,
      message_key: undefined,
      message_type: undefined
    }
  });
}
