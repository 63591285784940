import { addClass, removeClass } from './cssClassHelpers';

var collapsibleInputs = document.querySelectorAll('.ex-collapsible__input');
const collapsibleInputIds = [];
[].forEach.call(collapsibleInputs, function(collapsibleInput) {
  collapsibleInput.addEventListener('click', toggleCollapsible);
  collapsibleInputIds.push(collapsibleInput.id);
});

function toggleCollapsible(event) {
  closeOtherCollapsibles();
  const parent = event.target.parentNode;
  const parentId = parent.id;
  addClass(parent, 'ex-collapsible__item--active');
  const headline = parent.querySelector('.accordion__item__headline').textContent;

  const urlHash = '#ex-' + parent.id.split('-')[2];
  if (history.pushState) {
    history.pushState(null, null, urlHash);
  } else {
    location.hash = urlHash;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'ga4Event',
    event_name: 'navigation',
    navigation: {
      type: 'Accordion', //
      item: headline,
      module: 'Collapsible Container',

      event_category: 'Navigation',
      event_action: 'Accordion: ' + headline,
      event_label: headline,
      message_key: undefined,
      message_type: undefined
    }
  });

  const selectedImage = document.getElementById(parentId.replace('ex-collapsible-', 'ex-collapsible-image-'));
  const selectedImageWrapper = document.getElementById(parentId.replace('ex-collapsible-', 'ex-collapsible-image-')).parentNode.parentNode;
  addClass(selectedImage, 'ex-collapsible__image--active');
  addClass(selectedImageWrapper, 'ex-collapsible__imagewrapper--active');
}

function closeOtherCollapsibles() {
  var unselectedInputs = document.querySelectorAll('.ex-collapsible__input:not(:checked)');
  [].forEach.call(unselectedInputs, function(unselectedInput) {
    removeClass(unselectedInput.parentNode, 'ex-collapsible__item--active');
    const unselectedImage = document.getElementById(unselectedInput.parentNode.id.replace('ex-collapsible-', 'ex-collapsible-image-'));
    const unselectedImageWrapper = document.getElementById(unselectedInput.parentNode.id.replace('ex-collapsible-', 'ex-collapsible-image-')).parentNode.parentNode;
    removeClass(unselectedImage, 'ex-collapsible__image--active');
    removeClass(unselectedImageWrapper, 'ex-collapsible__imagewrapper--active');
  });
}

window.addEventListener('load', () => {
  hashNavigationListener();
}, false);

function hashNavigationListener() {
  const hash = location.hash.replace('#', '');

  if (hash.startsWith('ex-')) {
    const gcId = location.hash.split('-')[1];
    const collapsibleElementId = collapsibleInputIds.find(value => value.includes(gcId));
    if (collapsibleElementId) {
      const input = document.querySelector('#' + collapsibleElementId);
      input.click();
      input.parentNode.parentNode.parentNode.parentNode.parentNode.scrollIntoView(true);
    }
  }
}
