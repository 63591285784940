function createFileName(name) {
  return name.toLowerCase().replace(/[\W]+/g, '').slice(0, 16);
}

function ical_download(name, start, end) {
  let eventName = name;
  let fileName = createFileName(name);
  let dateStart = new Date(start);
  let dateEnd;

  if (!end) {
    dateEnd = new Date(start);
    dateEnd.setHours(23);
    dateEnd.setMinutes(59);
  } else {
    dateEnd = new Date(end);
  }

  //iso date for ical formats
  let _isofix = function(d) {
    let isoString = d.toISOString(); // 2022-05-30T07:00:00.000Z
    // 20220530T070000Z
    return isoString
        .replace('.000Z', 'Z')
        .replaceAll('-', '')
        .replaceAll(':', '');
  };

  let _save = function(fileURL) {
    if (!window.ActiveXObject) {
      var save = document.createElement('a');
      save.href = fileURL;
      save.target = '_blank';
      save.download = fileName || 'unknown';

      var evt = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      save.dispatchEvent(evt);

      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, '_blank');
      _window.document.close();
      _window.document.execCommand('SaveAs', true, fileName || fileURL);
      _window.close();
    }
  };

  var now = new Date();
  var ics_lines = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'PRODID:-//Phoenix Contact//ICS EventContainer//EN',
    'METHOD:REQUEST',
    'BEGIN:VEVENT',
    'UID:event-' + now.getTime() + '-phoenix-contact',
    'DTSTAMP:' + _isofix(now),
    'DTSTART:' + _isofix(dateStart),
    'DTEND:' + _isofix(dateEnd),
    'DESCRIPTION:' + eventName,
    'SUMMARY:' + eventName,
    'LAST-MODIFIED:' + _isofix(now),
    'SEQUENCE:0',
    'END:VEVENT',
    'END:VCALENDAR'
  ];

  var dlurl = 'data:text/calendar;base64,' + btoa(ics_lines.join('\r\n'));

  try {
    _save(dlurl);
  } catch (e) {
    console.log(e);
  }
}

window.ical_download = ical_download;
