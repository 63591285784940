import { addClass, removeClass } from './cssClassHelpers';

window.addEventListener('load', function() {
  const productList = document.querySelector('.ex-product-list');
  if (productList) {
    const sliceSize = productList.dataset.slice || 10;
    const fetchProductListItems = () => {
      const visibleProductListItems = Array.from(productList.querySelectorAll('.ex-product-listitem--visible'));
      // console.log('visibleProductListItems', visibleProductListItems)
      const promises = [];
      visibleProductListItems.forEach(entry => {
        promises.push(fetch(entry.dataset.include)
            .then(response => {
              if (response.ok) {
                return response.text();
              }
            })
            .then(body => {
              entry.innerHTML = body;
              delete entry.dataset.include;
              removeClass(entry, 'ex-product-listitem--visible')
            })
            .catch(e => console.error(`explore: Could not update product list item, message => ${e.message}`)));
      });
      return Promise.all(promises);
    };

    // load the initial products after pageLoad
    setTimeout(fetchProductListItems, 100);

    const loadMoreButton = document.querySelector('.ex-product-list__load-more');
    if (loadMoreButton) {
      loadMoreButton.addEventListener('click', async (e) => {
        e.target.disabled = true;
        const nextProductListItems = Array.from(productList.querySelectorAll('.ex-product-listitem--hidden'))
                .slice(0, sliceSize);
        console.log('nextProductListItems', nextProductListItems)
        nextProductListItems.forEach(entry => {
          removeClass(entry, 'ex-product-listitem--hidden')
          addClass(entry, 'ex-product-listitem--visible')
        })

        await fetchProductListItems();
        e.target.disabled = false;
        if(productList.querySelector('.ex-product-listitem--hidden') == null) {
          addClass(loadMoreButton, 'ex-product-list__load-more--hidden')
        }
      });
    }
  }
});