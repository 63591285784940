import { addClass, removeClass } from './cssClassHelpers';

const movingImageEmbedHandler = function movingImageEmbedHandler(poster) {
  return function(event) {
    event.preventDefault();
    const player = document.getElementById(poster.dataset.playerId);
    const playerId = poster.dataset.playerId.split('-')[2];
    player.style.display = 'block';
    poster.remove();
    addClass(player.parentNode, 'embed-responsive--poster-removed');

    const placeholder = document.getElementById('mi24-player-controls-placeholder-' + playerId);
    placeholder.style.height = '0';
    VideoPlayer.Collection.getPlayerById(player.id).play();
  };
};

function removeSeamlessOverlay(poster) {
  const player = document.getElementById(poster.dataset.playerId);
  const playerId = poster.dataset.playerId.split('-')[2];
  showElement(player)
  hideElement(poster.querySelector('img'))
  addClass(player.parentNode, 'embed-responsive--poster-removed');

  const placeholder = document.getElementById('mi24-player-controls-placeholder-' + playerId);
  placeholder.style.display = 'block';

  let videoNode = player.querySelector('video');
  // Video has to be muted otherwise Chrome (and probably other browsers) will not start playing the video (without a hint because the moving image api seems to catch it)
  videoNode.muted = true;
  VideoPlayer.Collection.getPlayerById(player.id).play();
}

function showPoster(player) {
  hideElement(player)
  // even though we don't show playercontrols in the seamless player we still compensate for that when the poster is shown
  // showing and hiding the placeholder seems the easiest way to handle that correctly in seamless mode
  const playerId = player.id.split('-')[2];
  const placeholder = document.getElementById('mi24-player-controls-placeholder-' + playerId);
  placeholder.style.display = 'none';

  let poster = player.parentNode.querySelector('.ex-movingimage__poster');
  showElement( poster.querySelector('img'))
  playerOutOfViewObserver.observe(poster);
}

let playerIntoViewObserver;
let playerOutOfViewObserver;
playerIntoViewObserver= new IntersectionObserver(function(posters, observer) {
  posters.forEach(function(poster) {
    // triggered when the overlay is scrolled into view
    if (poster.isIntersecting) {
      try {
        removeSeamlessOverlay(poster.target);
      } catch (error) {
        // this error occurs on page load because the player is not yet created but the surounding elements exist already
        let parentId = poster.target.parentNode.querySelector('.embed-responsive-item').id;
        waitForElm(`#${ parentId } video`).then(() => {
          // Timeout is not good, but I did not find a good clue on what to wait for here the video element exists before the JS player element exists
          // there are no callbacks that make sense here
          setTimeout(() => {
            removeSeamlessOverlay(poster.target);
          }, 1000);
        });
      }
      observer.unobserve(poster.target);
    }
  });
}, { threshold: 0.75 });
playerOutOfViewObserver = new IntersectionObserver(function(posters, observer) {
  posters.forEach(function(poster) {
    // this is triggered when the finished seamless video is not in the viewport anymore
    if (!poster.isIntersecting) {
      const player = document.getElementById(poster.target.dataset.playerId);
      removeClass(player.parentNode, 'embed-responsive--poster-removed');
      // register the observer to trigger once when the user scrolls the player into view
      playerIntoViewObserver.observe(poster.target);
      // remove self to prevent trigger loops
      observer.unobserve(poster.target);
    }
  })
}, { threshold: 0 });

const posters = document.querySelectorAll('.ex-movingimage__poster');

[].forEach.call(posters, function(poster) {
  const isSeamless = poster.dataset.seamless === 'true';

  // if seamless is enabled we don't need click handlers we just need the intersectobserver
  if (isSeamless) {
    playerIntoViewObserver.observe(poster);
  } else {
    poster.addEventListener('click', movingImageEmbedHandler(poster));
    poster.addEventListener('keydown', event => {
      if (event.keyCode === 13 || event.keyCode === 32) {
        movingImageEmbedHandler(poster)(event);
      }
    });
  }
});

//add the player
const head = document.getElementsByTagName('head')[0];
const script = document.createElement('script');
script.type = 'text/javascript';
script.src = 'https://e.video-cdn.net/v2/embed.js';
head.appendChild(script);

const pushGTMEvent = (details) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'ga4Event',
    event_name: details.type,
    [details.type]: {
      title: details.videoTitle,
      event_category: 'Videos',
      event_action: details.action,
      event_label: details.videoTitle,
      message_key: undefined,
      message_type: undefined
    }
  });
};

//create a new player instance (vmpro)
function createPlayer(element) {

  const initPlayer = {
    success: function(playerApi) {

      // do not use player given api as events are omitted and behaviour is odd
      const videoElement = element.querySelector('video');

      // case 1
      videoElement.addEventListener('playing', function(event) {
        const startedAt = event.target.dataset.startedAt;
        if (startedAt === undefined) {
          pushGTMEvent({
            type: 'video_start_playing',
            action: 'Start playing',
            videoTitle: element.dataset.videoTitle
          });
          event.target.dataset.startedAt = new Date().getTime();
        } else {
          pushGTMEvent({
            type: 'video_resume',
            action: 'Resume',
            videoTitle: element.dataset.videoTitle
          });
        }
      });

      videoElement.addEventListener('pause', function(event) {
        if (!event.target.ended) {
          pushGTMEvent({
            type: 'video_pause',
            action: 'Pause',
            videoTitle: element.dataset.videoTitle
          });
        }
      });

      // case 2
      videoElement.addEventListener('waiting', function() {
        pushGTMEvent({
          type: 'video_buffering',
          action: 'Buffering',
          videoTitle: element.dataset.videoTitle
        });
      });

      // case 3
      videoElement.addEventListener('timeupdate', function(event) {
        const remainingContentInPercent = Math.floor(event.time / event.target.duration * 100);
        const progressNotifications = event.target.dataset.progressNotifications || '';

        if (remainingContentInPercent >= 25 && progressNotifications.indexOf('25,') === -1) {
          event.target.dataset.progressNotifications += '25,';
          pushGTMEvent({
            type: 'video_reached_25',
            action: 'Reached 25',
            videoTitle: element.dataset.videoTitle
          });
        }

        if (remainingContentInPercent >= 50 && progressNotifications.indexOf('50,') === -1) {
          event.target.dataset.progressNotifications += '50,';
          pushGTMEvent({
            type: 'video_reached_50',
            action: 'Reached 50',
            videoTitle: element.dataset.videoTitle
          });
        }

        if (remainingContentInPercent >= 75 && progressNotifications.indexOf('75,') === -1) {
          event.target.dataset.progressNotifications += '75,';
          pushGTMEvent({
            type: 'video_reached_75',
            action: 'Reached 75',
            videoTitle: element.dataset.videoTitle
          });
        }
      });

      // case 4
      videoElement.addEventListener('ended', function() {
        if (element.dataset.seamless === 'true') {
          showPoster(element)
        }else {
          element.querySelector('.mi-big-play-cover').style.display = '';
        }

        pushGTMEvent({
          type: 'video_reached_end',
          action: 'Reached end',
          videoTitle: element.dataset.videoTitle
        });
      });
    },
    parameters: {
      configType: 'vmpro',
      playerId: element.dataset.seamless === 'true' ? '9U47wxDdk3v5KLXevfyV44' : '6bvyZ1FEsqswwqACpahDRB',
      videoId: element.dataset.videoId,
      apiUrl: '//d.video-cdn.net/play',
      flashPath: '//e.video-cdn.net/v2/'
    }
  };

  VideoPlayer.Collection.addPlayerById(element.id, initPlayer);
}

window.addEventListener('load', () => {
  const mi24Players = document.querySelectorAll('[data-player=ex-mi24-player]');
  VideoPlayer.Collection.rejectAnalytics();

  [].forEach.call(mi24Players, function(player) {
    createPlayer(player);
  });
});

//HELPER FUNCTIONS

function waitForElm(selector) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}

function showElement(element) {
  element.classList.remove("ex-hidden");
  element.classList.add("ex-visible");
}

function hideElement(element) {
  element.classList.remove("ex-visible");
  element.classList.add("ex-hidden");
}