function customFormStage() {
  var form = document.getElementById('ex-custom-stage__form');
  var headline = document.querySelector('.ex-custom-stage h1');

// Append the form status
  var formStatus = document.getElementById('ex-customformstage__formStatus');

  if (form) {

    // honeypot = bienesabbertopf code
    window.setTimeout(function() {
      if (document.getElementById('ex-bienensabbertopf')) {
        document.getElementById('ex-bienensabbertopf').required = false;
      }
      var realAction = form.dataset.action;
      if (realAction) {
        form.action = realAction;
      }
    }, 5000);

    function urlencodeFormData(f) {
      var s = '';

      function encode(s) {
        return encodeURIComponent(s).replace(/%20/g, '+');
      }

      for (var i = 0, ii = f.length; i < ii; ++i) {
        var input = f[i];
        s += (s ? '&' : '') + encode(input.name) + '=' + encode(input.value);
      }
      return s;
    }

    form.onsubmit = function(e) {
      // Stop the regular form submission
      e.preventDefault();
      console.log('form submit');

      // Construct an HTTP request
      var xhr = new XMLHttpRequest();
      xhr.open(form.method, form.action, true);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.send(urlencodeFormData(form));

      // Callback function
      xhr.onloadend = function(response) {
        var dataLayer = window.dataLayer || [];

        if (response.target.status === 200 || response.target.status === 201) {
          console.log('200');
          // Success
          formStatus.className = 'form-status alert alert-success';
          formStatus.innerHTML = form.dataset.formsuccess;

          dataLayer.push({
            event: "ga4Event",
            event_name: 'form_submit',
            form_submit : {
              type: 'Contact',
              event_category: "Forms",
              event_action: headline.textContent,
              event_label: "Submit Form",
              message_key: undefined,
              message_type: undefined
            }
          });

        } else {
          formStatus.className = 'form-status alert alert-danger';
          formStatus.innerHTML = form.dataset.formerror;
          dataLayer.push({
            event: "ga4Event",
            event_name: 'form_submit',
            form_submit : {
              type: 'Contact',
              event_category: "Forms",
              event_action: headline.textContent,
              event_label: "Submit Form",
              message_key: 'Error sending form. Status: ' + response.target.status,
              message_type: undefined
            }
          });
        }

      };
    };
  }
}

window.addEventListener('load', function() {
  customFormStage();
});
