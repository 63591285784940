import { addClass, hasClass, removeClass } from './cssClassHelpers';

const jmBar = document.querySelector('.ex-jumpmark-bar');
const header = document.querySelector('header.global-header');
let jmbHelper = document.querySelector('#jumpmark-bar-helper');
let closeButton = document.querySelector('.ex-jumpmark-bar-close');

const jmBarClosedClass = 'ex-jumpmark-bar__closed';
const closeButtonAnimationNotVisibleClass = 'ex-jumpmark-bar-close__not_visible';
const closeButtonAnimationVisibleClass = 'ex-jumpmark-bar-close__visible';

const mdBreakpoint = 991.98;
let jumpLinkClicked = false;
let isBelowMdBreakpoint = document.documentElement.clientWidth < mdBreakpoint;

window.addEventListener('DOMContentLoaded', function() {
  const links = document.querySelectorAll('[data-ex-jumpmark-item]');

  links.forEach(link => {
    link.addEventListener('click', () => {
      jumpLinkClicked = true;
      jmBar.style.top = '0px';
    });
  });

  window.dataLayer = window.dataLayer || [];

  for (var index = 0; index < links.length; index++) {
    const jumpBarNavItem = links[index];
    const targetId = jumpBarNavItem.dataset.exJumpmarkItem;
    const targetElement = document.querySelector('[data-ex-gcid="' + targetId + '"]');

    if (targetElement) {
      targetElement.classList.add('ex-jumpmark-bar-target');
      jumpBarNavItem.href = '#' + targetElement.id;
    }
  }
});

if (jmBar && header && !isBelowMdBreakpoint) {

  closeButton.addEventListener('click', () => {
    addClass(jmBar, jmBarClosedClass);
    removeClass(closeButton, closeButtonAnimationVisibleClass);
  });

  window.addEventListener('scroll', function() {

    let headerOffset = Math.max(header.offsetHeight +header.getBoundingClientRect().top, 0);

    if (jmBar.getBoundingClientRect().top > headerOffset && hasClass(jmBar, jmBarClosedClass)) {
      removeClass(jmBar, jmBarClosedClass);
    }

    if (jmbHelper.getBoundingClientRect().top + 1 < jmBar.getBoundingClientRect().bottom
        && !hasClass(jmBar, jmBarClosedClass)) {
      removeClass(closeButton, closeButtonAnimationNotVisibleClass);
      addClass(closeButton, closeButtonAnimationVisibleClass);
    } else if ((jmbHelper.getBoundingClientRect().top + 1 > jmBar.getBoundingClientRect().bottom)
        && !hasClass(jmBar, jmBarClosedClass)) {
      removeClass(closeButton, closeButtonAnimationVisibleClass);
      addClass(closeButton, closeButtonAnimationNotVisibleClass);
    }

    if (!jumpLinkClicked) {
      let headerTop = header.getBoundingClientRect().top;
      let calculatedHeaderOffset = Math.max(header.offsetHeight + headerTop, 0);
      jmBar.style.top = ''.concat(calculatedHeaderOffset, 'px');
    } else {
      jumpLinkClicked = false;
    }
  });
}
