import { addGTMClickEventsListeners, addViewEventsOnIntersection, triggerViewEvent } from './swiperGtmEventTracking';

const heroStageV2Video = document.querySelector('.ex-herostage-v2 video');

if (heroStageV2Video) {
  heroStageV2Video.play();

}

window.addEventListener('DOMContentLoaded', function() {
  const links = document.querySelectorAll('.ex-herostage-cta');

  for (var index = 0; index < links.length; index++) {
    const heroStageCTAItem = links[index];
    const targetId = heroStageCTAItem.href.split("#ex-")[1];
    const targetElement = document.querySelector('[data-ex-gcid="' + targetId + '"]');

    if (targetElement) {
      heroStageCTAItem.href = '#' + targetElement.id;
    }
  }
});