export const pushPromotionImpression = function(id, title, type, position) {
  pushGAPromoEvent('EECpromotionImpression', 'promoView', id, title, type, position);
};

export const pushPromotionClick = function(id, title, type, position) {
  pushGAPromoEvent('EECpromotionClick', 'promoClick', id, title, type, position);
};

export const pushGAPromoEvent = function(event, event_name, id, title, type, position) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': event,
    'messageKey': undefined,
    'messageType': undefined,
    'ecommerce': {
      [event_name]: {
        'promotions': [
          {
            'id': id,
            'name': title,
            'creative': type,
            'position': position
          }
        ]
      }
    }
  });
};

export const pushGa4PromotionView = function(id, title, type, position) {
  pushGA4PromoEvent('view_promotion',
      id, title, type, position);
};

export const pushGa4PromotionClick = function(id, title, type, position) {
  pushGA4PromoEvent('select_promotion',
      id, title, type, position);
};

export const pushGA4PromoEvent = function pushGA4PromoEvent(event, id, title, type, position) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event,
    ecommerce: {
      items: [
        {
          promotion_id: id,
          promotion_name: title,
          creative_name: type,
          creative_slot: position,
          location_id: undefined, // for future use
          item_name: undefined, // for future use
          item_id: undefined, // for future use
          price: undefined, // for future use
          item_brand: undefined, // for future use
          item_category: undefined, // for future use
          item_category2: undefined, // for future use
          item_category3: undefined, // for future use
          item_category4: undefined, // for future use
          item_category5: undefined, // for future use
          item_variant: undefined, // for future use
          item_list_name: undefined, // for future use
          item_list_id: undefined, // for future use
          index: undefined, // for future use
          quantity: undefined // for future use
        }]
    }
  });
};

export default {
  pushPromotionImpression,
  pushPromotionClick,
  pushGAPromoEvent,
  pushGa4PromotionView,
  pushGa4PromotionClick,
  pushGA4PromoEvent
};
