let LINK_TYPE_MAP = [
  { type: 'CONFIGURATOR', actionLabel: 'Configurator' },
  { type: 'CUSTOMER_PAGE', actionLabel: 'Customer Page' },
  { type: 'EDITORIAL_PAGE', actionLabel: 'Editorial Page' },
  { type: 'EXTERNAL', actionLabel: 'Extern' },
  { type: 'PRODUCT_CATEGORY_PAGE', actionLabel: 'Product Category Page' },
  { type: 'PRODUCT_DETAIL_PAGE', actionLabel: 'Product Detail Page' },
  { type: 'PRODUCT_LIST_PAGE', actionLabel: 'Product List Page' },
  { type: 'SEARCH_RESULT_PAGE', actionLabel: 'Search Result Page' },
  { type: 'CRISP_LINK', actionLabel: 'Crisp Link' }
];

let MODULE_NAME_MAP = [
  { module: 'Newsdetail Page', className: 'ex-newdetailpage__link' },
  { module: 'Newsdetail Page', className: 'ex-newsdetailpage-cta' },
  { module: 'Newsdetail Page', className: 'ex-newsdetailpage-news-container-sidebar-latest__card-headline' },
  { module: 'Seminar Detail Page', className: 'ex-seminardetail-page-cta' },
  { module: 'Details Overview', className: 'ex-do-item' },

  { module: 'Hero Stage', className: 'ex-herostage-cta' },
  { module: 'Info Stage', className: 'ex-infostage__link' },
  { module: 'Download Form Stage', className: 'ex-download-formstag-cta' },

  { module: 'New Product', className: 'ex-new-products__link' },
  { module: 'Jumpmark Bar', className: 'ex-jumpmark-bar__link' },
  { module: 'Image Map', className: 'ex-imagemap_overlaylink' },
  { module: 'Content Teaser Extended', className: 'ex-content-teaser-extended__teaser' },
  { module: 'Content Teaser', className: 'ex-content-teaser__link' },
  { module: 'Content Table', className: 'ex-content-table__footerlink' },
  { module: 'Collapsible Container', className: 'ex-collapsible-container_link' },
  { module: 'Newsletter Teaser', className: 'ex-newsletter-teaser-cta-link' },
  { module: 'Reference Teaser', className: 'ex-reference-teaser__button' },
  { module: 'Standalone Link', className: 'ex-standalone-link-link' },
  { module: 'Events Container', className: 'ex-news-grid-button' }
];

const EXCLUDE_SELECTOR_LIST = ['.ex-new-products__link', '.main-navigation__link', '.main-navigation__dropdown-content-link',
  '#secondary-navigation__action-toggleMenu', '.global-header__logo', '#cu-locale-toggle a'];

let fullyInViewElements = [];
let partlyInViewElements = [];

function isCTALink(currentElement) {
  return currentElement.classList.contains('btn') && currentElement.classList.contains('btn-primary');
}

window.addEventListener('load', function() {
  (function() {
    var absoluteHrefs = document.querySelectorAll('a');
    var excludedLinks = EXCLUDE_SELECTOR_LIST.reduce((elements, selector) => {
      elements.push(...document.querySelectorAll(selector));
      return elements;
    }, []);

    /****CLICK EVENTS****/
    for (var i = 0; i < absoluteHrefs.length; i++) {

      if (excludedLinks.find(element => element === absoluteHrefs[i])) {
        continue;
      }

      const currentElement = absoluteHrefs[i];
      let url = currentElement.href;
      if (/\.(pdf|docx|doc|jpg|jpeg|png|zip|bin|msi|iso)/.test(currentElement.href)) {
        // download link tracking
        currentElement.addEventListener('click', function(e) {
          console.log(currentElement);
          console.log(this);
          console.log(e);
          var action = currentElement.href.match(/.*\/(.*\.(pdf|docx|doc|jpg|jpeg|png|zip|bin|msi|iso))/)[1];
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'gaEvent',
            eventCategory: 'Downloads',
            eventAction: action,
            eventLabel: url,
            message_key: undefined,
            message_type: undefined
          });
        });
      } else if (currentElement.classList.contains('ex-contact-footer__phone')) {
        currentElement.addEventListener('click', function(e) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'ga4Event',
            event_name: 'phone_number_click',
            phone_number_click: {
              contact_partner: document.querySelector('.ex-contact-footer__name').textContent,
              event_category: 'Phone Number Click',
              event_action: 'Phone',
              event_label: document.querySelector('.ex-contact-footer__name').textContent,
              message_key: undefined,
              message_type: undefined
            }
          });
        });
      } else if (currentElement.classList.contains('ex-contact-footer__email')) {
        currentElement.addEventListener('click', function(e) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'ga4Event',
            event_name: 'email_click',
            email_click: {
              contact_partner: document.querySelector('.ex-contact-footer__name').textContent,
              event_category: 'E-Mail Click',
              event_action: 'Mail',
              event_label: document.querySelector('.ex-contact-footer__name').textContent,
              message_key: undefined,
              message_type: undefined
            }
          });
        });
      } else if (currentElement.classList.contains('ex-contact-sidebar__email')) {
        currentElement.addEventListener('click', function(e) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'ga4Event',
            event_name: 'email_click',
            email_click: {
              contact_partner: document.querySelector('.ex-contact-name').textContent.replaceAll(/\s+/g, ' ').trim(),
              event_category: 'E-Mail Click',
              event_action: 'Mail',
              event_label: document.querySelector('.ex-contact-name').textContent.replaceAll(/\s+/g, ' ').trim(),
              message_key: undefined,
              message_type: undefined
            }
          });
        });
      } else if (/^#./.test(currentElement.getAttribute('href'))) {
        currentElement.addEventListener('click', function(e) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'ga4Event',
            event_name: 'anchor',
            anchor: {
              type: 'Anchor',
              module: getModuleNameFor(currentElement),
              item: currentElement.textContent,
              event_category: 'Navigation',
              event_action: 'Anchor',
              event_label: currentElement.textContent,
              message_key: undefined,
              message_type: undefined
            }
          });
        });
      } else if (currentElement.hasAttribute('data-ex-link-type') && linkTypeAttributeContainsValidType(currentElement.getAttribute('data-ex-link-type'))) {
        // standalone link type tracking
        let linkType = currentElement.getAttribute('data-ex-link-type');
        currentElement.addEventListener('click', function(e) {
          window.dataLayer = window.dataLayer || [];
          if (isCTALink(currentElement)) {
            window.dataLayer.push({
              event: 'ga4Event',
              event_name: 'cta',
              cta: {
                link_url: url,
                type: getActionLabelFor(linkType),
                item: currentElement.textContent,
                module: getModuleNameFor(currentElement),
                event_category: 'CTA',
                event_action: getActionLabelFor(linkType),
                event_label: url,
                message_key: undefined,
                message_type: undefined
              }
            });
          } else {
            window.dataLayer.push({
              event: 'ga4Event',
              event_name: 'link_inbound',
              link_inbound: {
                link_domain: 'phoenixcontact.com',
                link_url: url,
                type: getActionLabelFor(linkType),
                module: getModuleNameFor(currentElement),
                event_category: 'Links',
                event_action: getActionLabelFor(linkType),
                event_label: url,
                message_key: undefined,
                message_type: undefined
              }
            });
          }
        });
      } else if (/phoenixcontact\.com/.test(currentElement.href)) {
        // all inbound links without link type
        currentElement.addEventListener('click', function(e) {
          if (isCTALink(currentElement)) {
            window.dataLayer.push({
              event: 'ga4Event',
              event_name: 'cta',
              cta: {
                link_url: url,
                type: undefined,
                item: currentElement.textContent,
                module: getModuleNameFor(currentElement),
                event_category: 'CTA',
                event_action: undefined,
                event_label: url,
                message_key: undefined,
                message_type: undefined
              }
            });
          } else {
            window.dataLayer.push({
              event: 'ga4Event',
              event_name: 'link_inbound',
              link_inbound: {
                link_domain: 'phoenixcontact.com',
                link_url: url,
                type: undefined,
                module: getModuleNameFor(currentElement),
                event_category: 'Links',
                event_action: undefined,
                event_label: url,
                message_key: undefined,
                message_type: undefined
              }
            });
          }
        });
      } else if (!/phoenixcontact\.com/.test(currentElement.href) && /^(https|http)/.test(currentElement.href)) {
        // outbound link tracking
        currentElement.addEventListener('click', function(e) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'gaEvent',
            eventCategory: 'Links',
            eventAction: 'Extern',
            eventLabel: url,
            messageKey: undefined,
            messageType: undefined
          });
        });
      }
    }
  })();
});

function linkTypeAttributeContainsValidType(attribute) {
  return !!LINK_TYPE_MAP.find(element => element.type === attribute);
}

function getActionLabelFor(linkType) {
  return (LINK_TYPE_MAP.find(element => element.type === linkType) || { actionLabel: 'Unknown Link Type' }).actionLabel;
}

function getModuleNameFor(linkElement) {
  console.log(linkElement.classList);
  return (MODULE_NAME_MAP.find(element => linkElement.classList.contains(element.className)) || { module: 'Unknown Module' }).module;
}

function addScrollIntoViewHandlerForHeroStage() {
  document.addEventListener('scroll', () => {
    const heroStage = document.querySelector('#ex-herostage');
    if (isFullyScrolledIntoView(heroStage)) {
      if (!fullyInViewElements.includes(heroStage.id)) {
        fullyInViewElements.push(heroStage.id);
        partlyInViewElements = partlyInViewElements.filter(value => value !== heroStage.id);
      }
    } else if (isPartlyScrolledIntoView(heroStage)) {
      if (!partlyInViewElements.includes(heroStage.id)) {
        partlyInViewElements.push(heroStage.id);
        fullyInViewElements = fullyInViewElements.filter(value => value !== heroStage.id);
      }
    } else if (fullyInViewElements.length > 0 || partlyInViewElements.length > 0) {
      fullyInViewElements = fullyInViewElements.filter(value => value !== heroStage.id);
      partlyInViewElements = partlyInViewElements.filter(value => value !== heroStage.id);
    }
  });
}

function isFullyScrolledIntoView(el) {
  if (!el) {
    return false;
  }
  var rect = el.getBoundingClientRect();
  var elemTop = rect.top;
  var elemBottom = rect.bottom;
  var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
  return isVisible;
}

function isPartlyScrolledIntoView(el) {
  if (!el) {
    return false;
  }
  var rect = el.getBoundingClientRect();
  var elemTop = rect.top;
  var elemBottom = rect.bottom;
  var isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}
