import { pushGa4PromotionClick, pushGa4PromotionView, pushPromotionClick, pushPromotionImpression } from './gtmPromotionTracking';

export const addViewEventsOnIntersection = function addViewEventsOnIntersection(swiper) {
  const options = {
    root: null,
    threshold: 0.5
  };
  const observer = new IntersectionObserver(handleIntersect, options);
  observer.observe(swiper.el);
};

function handleIntersect(entries, observer) {
  entries.forEach((entry) => {
    if (!entry.isIntersecting) {

    } else {
      const activeSlide = entry.target.querySelector('.swiper-slide-active');
      triggerViewEvent(activeSlide);
      observer.unobserve(entry.target);
    }
  });
}

export const triggerViewEvent = function triggerViewEvent(slide) {
  const id = slide.dataset.exGcid || undefined;
  const title = slide.dataset.title || undefined;
  const type = slide.dataset.type || undefined;
  const position = 'Position ' + slide.dataset.position;
  pushPromotionImpression(id, title, type, position);
  pushGa4PromotionView(id, title, type, position);
};

export const triggerClickEvent = function triggerClickEvent(slide, item, module) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'ga4Event',
    event_name: 'image_navigation',
    image_navigation : {
      type: 'Slider',
      item: item,
      module: module,
      event_category: 'Navigation',
      event_action: 'Slider',
      event_label: item,
      message_key: undefined,
      message_type: undefined
    }
  });
}

export const addGTMClickEventsListeners = function addGTMClickEventsListeners(querySelector) {
  const ctaLinks = document.querySelectorAll(querySelector);
  [].forEach.call(ctaLinks, function(ctaLink) {
    ctaLink.addEventListener('click', (event) => {
      const id = ctaLink.dataset.exGcid;
      const title = ctaLink.dataset.title;
      const type = ctaLink.dataset.type;
      const position = 'Position ' + ctaLink.dataset.position;
      pushPromotionClick(id, title, type, position);
      pushGa4PromotionClick(id, title, type, position);
    });
  });
};

export default {
  triggerViewEvent,
  addGTMClickEventsListeners,
  triggerClickEvent
};
