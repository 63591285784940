import Swiper, { Navigation, Pagination, Thumbs } from 'swiper';
import { hasClass } from './cssClassHelpers';
import { addViewEventsOnIntersection, triggerClickEvent, triggerViewEvent } from './swiperGtmEventTracking';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Thumbs]);

const sliders = document.querySelectorAll('.ex-imageslider');

[].forEach.call(sliders, function(slider) {
  const gcId = slider.dataset.gcId;
  const htmlId = slider.id;
  const isLargeSlider = hasClass(slider, 'ex-imageslider--large');
  const selector = '#' + htmlId + ' ';

  const galleryThumbs = new Swiper(selector + '.ex-imageslider__thumbnailslider', {
    spaceBetween: 20,
    slidesPerView: 'auto',
    loop: true,
    centeredSlides: true,
    watchSlidesVisibility: true,
    autoScrollOffset: 3
  });

  const swiperConfig = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    loop: true,
    lazy: true,
    centeredSlides: true,
    navigation: {
      nextEl: selector + '.swiper-button-next',
      prevEl: selector + '.swiper-button-prev'
    },
    on: {
      init: function(swiper) {
        addViewEventsOnIntersection(swiper);
      },
      realIndexChange: function(swiper) {
        triggerViewEvent(swiper.el.querySelector('.swiper-slide-active'));
        if(swiper.previousIndex < swiper.activeIndex) {
          triggerClickEvent(swiper.el.querySelector('.swiper-slide-active'), 'Slide Right', 'Image Slider')
        }else {
          triggerClickEvent(swiper.el.querySelector('.swiper-slide-active'), 'Slide Left', 'Image Slider')
        }
      }
    }
  };

  if (isLargeSlider) {
    swiperConfig.thumbs = {
      swiper: galleryThumbs
    };
  }

  const swiper = new Swiper(selector + '.swiper', swiperConfig);

});
