import Swiper from 'swiper';
import { addGTMClickEventsListeners, addViewEventsOnIntersection, triggerViewEvent } from './swiperGtmEventTracking';

new Swiper('.ex-hero-stage.swiper', {
  slidesPerView: 1,
  autoHeight: true,
  rewind: true,
  centeredSlides: true,
  allowTouchMove: true,
  navigation: {
    nextEl: '.ex-herostage__button-next',
    prevEl: '.ex-herostage__button-prev'
  },
  pagination: {
    clickable: true,
    el: '.swiper-pagination'
  },
  on: {
    init: function(swiper) {
      addViewEventsOnIntersection(swiper);
      addGTMClickEventsListeners('.ex-herostage-cta')
      slideUpdate(swiper);
    },
    slideChangeTransitionEnd: function(swiper) {
      triggerViewEvent(swiper.slides[swiper.realIndex]);
      slideUpdate(swiper);
    },
    setTranslate: function(swiper, translate) {
      for (var i = 0; i < swiper.slides.length; i++) {
        let offset = i * swiper['size'] + translate;
        swiper.slides[i].querySelector('.ex-hero-stage__slide__content').style.transform = 'translate3d(' + offset + 'px, 0, 0)';
      }
    }
  }
});

window.addEventListener('load', function() {
  const singleSlideHeroStage = document.querySelector('.ex-hero-stage--noslider');
  if (singleSlideHeroStage) {
    const cta = singleSlideHeroStage.querySelector('#ex-herostage-actionURL-1');
    if (cta) {
      cta.setAttribute('tabindex', '0');
    }
  }
});

function slideUpdate(swiper) {
  if (!swiper || !swiper.visibleSlides || !swiper.visibleSlides[0])
    return;

  let allSlides = swiper.slides;
  let visibleSlideId = swiper.visibleSlides[0].id;

  allSlides.forEach(slide => {
    let slideId = slide.id;
    let slideButton = document.querySelector('#' + slideId + ' a');

    // enable tabindex on visible CTA button, disable it on all others
    if(slideButton) {
      if (slideId === visibleSlideId) {
        slideButton.setAttribute('tabindex', '0');

      } else {
        slideButton.setAttribute('tabindex', '-1');
      }
    }
  });

  playVideoOnActiveSlide(swiper.slides, visibleSlideId);
}

function playVideoOnActiveSlide(slides, visibleSlideId) {
  slides.forEach(slide => {
    let slideId = slide.id;
    let videoElement = document.querySelector('#' + slideId + ' video');
    if (videoElement)
      videoElement.pause();
  });

  const activeSlide = document.getElementById(visibleSlideId);
  const activeVideoElement = activeSlide.getElementsByTagName('video');
  if (activeVideoElement && activeVideoElement[0])
    activeVideoElement[0].play();
}

const singleVideoSlide = document.querySelector('.ex-hero-stage--noslider video');

if (singleVideoSlide) {
  singleVideoSlide.play();
}
