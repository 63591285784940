import './collapsible';
import './custom_form_stage';
import './gtm_link_tracking';
import './highlights';
import './imagemap';
import './imageslider';
import './jumpmark_bar_v2';
import './product_list_item';
import './youtubevideo';
import './movingimage';
import './herostage';
import './breadcrumb';
import './newproductsslider';
import './details_overview_v7';
import './eventscontainer';
import "@pxc/crisp-oss-ui-components/dist/crisp-vue-components-icons.umd.js"
import './modelviewer';
import './herostagev2';

// initialize
document.addEventListener("DOMContentLoaded", function(){

  if(window.objectFitImages) {
    window.objectFitImages();
  }
  else console.warn('objectFitImages not available');

});