const videoEmbedHandler = (poster, event) => {
  event.preventDefault();
  const iframe = poster.parentNode.querySelector('iframe');
  iframe.src = iframe.dataset.src;
  poster.parentNode.removeChild(poster);
  iframe.style.display = 'block';
};

const posters = document.getElementsByClassName('ex-youtubevideo__poster');

[...posters].forEach(poster => {
  poster.addEventListener('click', event => videoEmbedHandler(poster, event));
  poster.addEventListener('keydown', event => {
    if(event.keyCode == 13 || event.keyCode == 32) {
      videoEmbedHandler(poster, event);
    }
  });
});