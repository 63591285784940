let appliedFilters = [];
let url = new URL(window.location.href);
let search_params = url.searchParams;
let currentlyOpenMenuId = '';
const CLEAR_CONDITION = '';
const OVERVIEW_TYPE = 'ex-ot-';
const OVERVIEW_SORT = 'ex-os-';

//generate link for invalid filters alter
let aTag = document.getElementById('ex-do-invalid-filter-link');
if (aTag != null) {
  url.searchParams.delete('filters');
  aTag.setAttribute('href',
      window.location.pathname + '?' + url.searchParams.toString());
}

// load filters on page load
if (aTag == null && (url.searchParams.get('filters') || url.searchParams.get('filters') === '')) {
  if (url.searchParams.get('filters') !== CLEAR_CONDITION) {
    let filters = url.searchParams.get('filters').split(',');
    let typeFilterSet = false;
    let sortFilterSet = false;
    [].forEach.call(filters, function(filterItemId) {
      appliedFilters.push(
          {
            id: filterItemId,
            text: document.getElementById(filterItemId)?.innerText.trim()
          }
      );
      let prefix = filterItemId.startsWith(OVERVIEW_TYPE) || filterItemId.startsWith(OVERVIEW_SORT) ? 'radio-' : 'checkbox-';
      const checkboxItem = document.getElementById(prefix + filterItemId);
      if (checkboxItem) {
        checkboxItem.checked = true;
        if (checkboxItem.id.includes(OVERVIEW_SORT)) {
          sortFilterSet = true;
        } else if (checkboxItem.id.includes(OVERVIEW_TYPE)) {
          typeFilterSet = true;
        }
      }
    });
  }
  document.querySelector('.ex-details-overview').scrollIntoView();
}

// show and hide dropdown menue
const FILTER_HEAD_CLASSNAME_PREFIX = 'ex-do-filter-select-head';
const filterButtons = document.getElementsByClassName(FILTER_HEAD_CLASSNAME_PREFIX);
[].forEach.call(filterButtons, function(filterButton) {
  const menuId = 'ex-do-menu-' + filterButton.id.substring(FILTER_HEAD_CLASSNAME_PREFIX.length + 1);

  filterButton.addEventListener('click', (event) => {
    console.log('filterButton: ', event);
    currentlyOpenMenuId = currentlyOpenMenuId === '' ? menuId : '';
    filterButton.getElementsByClassName('dropdown-arrow')[0].classList.toggle('ico-arrow-down');
    filterButton.getElementsByClassName('dropdown-arrow')[0].classList.toggle('ico-arrow-up');
    document.getElementById(menuId).classList.toggle('show');
    reloadFilter(appliedFilters);
  });
});

// apply filter button
let applyButtons = document.getElementsByClassName('ex-do-filter-select-dropdown-button-apply');
[].forEach.call(applyButtons, function(button) {
  button.addEventListener('click', (event) => {
    console.log('applyButtons: ', event);
    let buttonTagType = button.dataset.tagType;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'ga4Event',
      event_name: 'filter',
      filter: {
        type: buttonTagType,
        selection: getAppliedFiltersForType(appliedFilters, buttonTagType),
        mask: 'News Events Seminars',
        filter_id: undefined,
        event_category: 'Filter',
        event_action: getAppliedFiltersForType(appliedFilters, buttonTagType),
        event_label: undefined,
        message_key: undefined,
        message_type: undefined
      }
    });

    reloadFilter(appliedFilters);
  });
});

// clear filters button
let clearButtons = document.getElementsByClassName('ex-do-filter-select-dropdown-button-clear');
[].forEach.call(clearButtons, function(button) {
  button.addEventListener('click', (event) => {
    console.log('clearButtons: ', event);
    let menuId = button.dataset.menuId;
    let checkBoxes = document.querySelectorAll('#' + menuId + '  > ul > li > div > input');
    [].forEach.call(checkBoxes, function(checkBox) {
      checkBox.checked = false;
    });

    appliedFilters = appliedFilters.filter(filter => {
      return !checkBoxesContainFilter(checkBoxes, filter);
    });
    reloadFilter(appliedFilters);
  });
});

// checkbox
let checkboxes = document.getElementsByClassName('ex-do-multiselect-checkbox');
[].forEach.call(checkboxes, function(checkbox) {
  checkbox.addEventListener('click', (event) => {
    console.log('checkboxes: ', event);
    let tagId = checkbox.dataset.tagId;
    let tagType = checkbox.dataset.tagType;
    if (checkbox.checked) {
      appliedFilters.push(
          {
            id: tagId,
            type: tagType,
            text: checkbox.dataset.tagTitle
          }
      );
    } else {
      appliedFilters = appliedFilters.filter(checkedFilter => checkedFilter.id !== tagId);
    }
  });
});

// close dropdown menus on click anywhere other than the menu
document.addEventListener('click', event => {
  const dropdownMenue = document.querySelector('.ex-do-menu.show');
  const dropdownHeadArrow = document.querySelector('.dropdown-arrow.ico-arrow-up');
  if (currentlyOpenMenuId !== '') {
    const openDropDown = document.querySelector('[data-menu-id=' + currentlyOpenMenuId + ']');

    if (openDropDown.contains(event.target)) {
      return;
    }
  }

  if (dropdownMenue && !dropdownMenue.contains(event.target)) {
    dropdownHeadArrow.classList.toggle('ico-arrow-down');
    dropdownHeadArrow.classList.toggle('ico-arrow-up');
    dropdownMenue.classList.remove('show');
    reloadFilter(appliedFilters);
  }

}, true);

// applied filter buttons
let appliedFilterButtons = document.getElementsByClassName('ex-do-applied-filter-button');
[].forEach.call(appliedFilterButtons, function(appliedFilterButton) {
  appliedFilterButton.addEventListener('click', (event) => {
    console.log('appliedFilterButtons: ', event);
    appliedFilters = appliedFilters.filter(filter => filter.id !== appliedFilterButton.dataset.tagId);
    appliedFilterButton.remove();
    reloadFilter(appliedFilters);
  });
});

// clear all button
let clearAllButton = document.getElementById('ex-do__clear-all-button');
if (clearAllButton) {
  clearAllButton.addEventListener('click', (event) => {
    console.log('clearAllButton: ', event);
    reloadFilter([]);
  });
}

// single select
const singleSelect = document.getElementsByClassName('ex-do-single-select-checkbox');

[].forEach.call(singleSelect, function(checkbox) {
  checkbox.addEventListener('click', (event) => {
    console.log('singleSelect: ', event);

    if (checkbox.checked) {

      const tagId = checkbox.dataset.tagId;
      const tagType = checkbox.dataset.tagType;
      const tagTitle = checkbox.dataset.tagTitle;

      appliedFilters = appliedFilters.filter(filter => {

        if (tagId.startsWith(OVERVIEW_SORT) && filter.id.startsWith(OVERVIEW_SORT)) {
          return false;
        } else if (tagId.startsWith(OVERVIEW_TYPE) && filter.id.startsWith(OVERVIEW_TYPE)) {
          return false;
        }

        return !filter.id.includes(tagId);
      });

      if (!(tagId.startsWith(OVERVIEW_TYPE) && tagId.endsWith('-0'))) {
        appliedFilters.push(
            {
              id: tagId,
              type: tagType,
              text: tagTitle
            }
        );
      }
    }
    reloadFilter(appliedFilters);
  });
});

function getAppliedFiltersForType(appliedFilters, type) {
  return appliedFilters.filter(filter => filter.type === type).reduce((string, filter) => {
    if (string === '') return string + filter.id;
    else return string + ',' + filter.id;
  }, '');
}

function checkBoxesContainFilter(checkBoxes, filter) {
  return Array.from(checkBoxes).filter(
      checkbox => {
        return checkbox.dataset.tagId === filter.id;
      }
  ).length;
}

function reloadFilter(filters) {
  let filterMap = filters.map(filter => filter.id);

  if (search_params.get('filters') === filterMap.toString() || !(search_params.get('filters') || filterMap.toString())) {
    return;
  }

  if (filterMap.toString()) {
    search_params.set('filters', filterMap);
  } else {
    search_params.set('filters', CLEAR_CONDITION);
  }

  url.search = search_params.toString();
  window.location.replace(url.toString());
}

function checkAndSetFilter(id, type, setFlag) {
  const checkboxItem = document.getElementById(`${ type }-${ id }`);
  if (checkboxItem) {
    checkboxItem.checked = true;
    setFlag = true;
  }
  return setFlag;
}

// Adjust image sizes
function changeWideImages(image) {
  const imageAspectRatio = image.naturalWidth / image.naturalHeight;

  if (imageAspectRatio >= 16 / 9) {
    image.style.width = 'auto';
    image.style.height = '200px';
    image.style.aspectRatio = 'unset';
  }
}

const images = document.getElementsByClassName('ex-do-item__headrow__image_newproduct');
for (let i = 0; i < images.length; i++) {
  let image = images.item(i);
  image.onload = function() {
    changeWideImages(image);
  };

  changeWideImages(image);
}