import Swiper, { EffectFade, Manipulation, Scrollbar, FreeMode, HashNavigation } from 'swiper';

const newProductsContainers = document.querySelectorAll('.ex-new-products');
[].forEach.call(newProductsContainers, function(newProductsContainer) {

  const htmlId = newProductsContainer.id;
  const parentSelector = '#' + htmlId;
  const thumbnailSliderSelector = parentSelector + ' .ex-new-products-slider__thumbnailslider';
  const contentSliderSelector = parentSelector + ' .ex-new-products-slider__contentslider';

  if(!newProductsContainer.querySelector('.ex-new-products-slider__thumbnailslider'))
    return;

  const slideCount = newProductsContainer.querySelector('.ex-new-products-slider__thumbnailslider').querySelectorAll('.swiper-slide').length;

  let largeScreenParameters = {
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    spaceBetween: 24 - 10,
    navigation: {
      enabled: true
    }
  };

  var thumbs = new Swiper(thumbnailSliderSelector, {
    modules: [Manipulation, Scrollbar, FreeMode],
    breakpoints: {
      0: {
        slidesOffsetBefore: 12,
        slidesOffsetAfter: 12,
        slidesPerView: slideCount > 2 ? 2.5 : 2,
        slidesPerGroup: slideCount > 2 ? 2 : 1,
        spaceBetween: 12,
        navigation: {
          enabled: false
        },
        scrollbar: {
          dragSize: 100,
          draggable: false,
          hide: true
        }
      },
      576: {
        allowTouchMove: slideCount > 2,
        scrollbar: {
          hide: slideCount <= 2
        }
      },
      768: {
        ...largeScreenParameters,
        slidesPerView: 3 <= slideCount ? 3 : 'auto',
        slidesPerGroup: 3,
        scrollbar: {
          dragSize: 150
        }
      },
      992: {
        ...largeScreenParameters,
        slidesPerView: 4 <= slideCount ? 4 : 'auto',
        slidesPerGroup: 4,
        scrollbar: {
          dragSize: 200
        }
      },
      1200: {
        ...largeScreenParameters,
        slidesPerView: 5 <= slideCount ? 5 : 'auto',
        slidesPerGroup: 5,
        scrollbar: {
          dragSize: 250
        }
      }
    },
    watchSlidesProgress: true,
    navigation: {
      prevEl: parentSelector + ' .ex-new-products-slider__button-prev',
      nextEl: parentSelector + ' .ex-new-products-slider__button-next',
      enabled: true
    },
    scrollbar: {
      el: parentSelector + ' .swiper-scrollbar',
      snapOnRelease: false,
      draggable: true,
      hide: false
    },
    freeMode: true,
    parentSelector: parentSelector,
    on: {
      afterInit: swiper => {
        showOrHideScrollbarWrapper(swiper);
        checkForNoNavButtons(parentSelector);
      },
      resize: swiper => {
        showOrHideScrollbarWrapper(swiper);
        checkForNoNavButtons(parentSelector);
      }
    }
  });

  var content = new Swiper(contentSliderSelector, {
    modules: [EffectFade, HashNavigation],
    noSwiping: true,
    effect: 'fade',
    autoHeight: true,
    hashNavigation: {
      replaceState: true
    },
    fadeEffect: {
      crossFade: true
    },
    thumbs: {
      swiper: thumbs
    }
  });
});

function showOrHideScrollbarWrapper(swiper) {
  const scrollbarWrapper = document.querySelector(swiper.params.parentSelector + ' .ex-new-products-scrollbar__wrapper');

  if (scrollbarWrapper.querySelector('.swiper-scrollbar-lock')) {
    scrollbarWrapper.setAttribute('style', 'display: none;');
  } else {
    scrollbarWrapper.removeAttribute('style');
  }
}

function checkForNoNavButtons(parentSelector) {
  console.log(parentSelector + " check for nav buttons")
  const thumbRow = document.querySelector(parentSelector + ' .ex-new-products-slider__thumbnailrow');
  const lockedNavButton = thumbRow.querySelector('.swiper-button-lock');
  if (lockedNavButton != null) {
    thumbRow.classList.add('ex-new-products-slider-thumbs__no-nav-buttons');
  } else {
    thumbRow.classList.remove('ex-new-products-slider-thumbs__no-nav-buttons');
  }
}
