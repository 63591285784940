import { addClass, removeClass } from './cssClassHelpers';

function closeAllOverlays() {
  var openOverlays = document.querySelectorAll('.ex-imagemap__overlay--open');
  [].forEach.call(openOverlays, function(openOverlay) {
    removeClass(openOverlay, 'ex-imagemap__overlay--open');
  });
}

var imageMaps = document.querySelectorAll('.ex-imagemap__wrapper');
[].forEach.call(imageMaps, function(imageMap) {
  imageMap.addEventListener('click', closeAllOverlays);
});

var markers = document.querySelectorAll('.ex-imagemap__marker');
[].forEach.call(markers, function(marker) {
  if (parseInt(marker.style.left) > 50) {
    addClass(marker, 'ex-imagemap__marker--left');
  } else {
    addClass(marker, 'ex-imagemap__marker--right');
  }

  var overlay = marker.querySelector('.ex-imagemap__overlay');
  marker.addEventListener('keydown', event => {
    if (event.keyCode == 13 || event.keyCode == 32) {
      event.stopPropagation();
      closeAllOverlays();
      addClass(overlay, 'ex-imagemap__overlay--open');
    }
  });

  marker.addEventListener('click', function(event) {
    event.stopPropagation();
    closeAllOverlays();
    addClass(overlay, 'ex-imagemap__overlay--open');


    if(window.innerWidth < 768) {
      var padding = window.innerWidth * 0.1;
      var positionLeft = -marker.getBoundingClientRect().left + padding;
      overlay.style.left = positionLeft + "px";
    }

    let title = overlay.querySelector('.ex-imagemap__overlaytitle')
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'ga4Event',
      event_name: 'image_navigation',
      image_navigation : {
        type: 'Image Map',
        item: title ? title.textContent : 'ERROR',
        module: 'Image Map Marker',

        event_category: 'Navigation',
        event_action: 'Image Map',
        event_label: title ? title.textContent : 'ERROR',
        message_key: undefined,
        message_type: undefined
      }
    });

  });

  var closeBtn = marker.querySelector('.ex-imagemap__overlayclosebtn');
  closeBtn.addEventListener('click', function(event) {
    event.stopPropagation();
    removeClass(overlay, 'ex-imagemap__overlay--open');
  });
  closeBtn.addEventListener('keydown', event => {
    if (event.keyCode == 13 || event.keyCode == 32) {
      event.stopPropagation();
      removeClass(overlay, 'ex-imagemap__overlay--open');
    }
  });
});
